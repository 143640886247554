body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  
  color: white;
  background-image: url('./assets/brickbg.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 1; 
}
.App {
  text-align: center;
   
}



.App-header {
  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease; 
}

.header-scrolled {
  width: 100vw;
  background: rgba(0, 0, 0, 0.374);
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

