
.navbar {
    list-style: none;
    display: flex;
    padding: 5px;
    margin: 5px;
    position: sticky;
    top: 0;
    width: 100%;
    
}

.nav-item {
    margin: 0 15px;
}

.nav-item a {
    text-decoration: none;
    color: white;
    padding: 5px 0;
    position: relative;
    font-size: large;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.nav-item a::after {
    content: "";
    display: block;
    height: 2px;
    width: 0;
    background: gold;
    transition: width 0.3s ease;
    position: absolute;
    left: 0;
    bottom: 0;
}

.nav-item a:hover::after {
    width: 100%;
}

#profile-pic {
    transition: all 1s ease-in-out;
}

#profile-pic.logo {
    width: 40px;
    height: 40px;
    position: fixed;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    z-index: 1001;
    border: 2px solid white;

}
