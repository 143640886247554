body {
  color: white;
}

.Projects-Container {
  padding: 50px;
  text-align: center;
}

.Projects-Heading {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.Projects-Carousel .carousel .slide {
  background: none;
}

.Projects-Carousel .control-arrow,
.Projects-Carousel .carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
}

.Projects-Carousel .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}

.Projects-Carousel .control-arrow:before {
  font-size: 20px;
  color: white;
}

.Projects-Carousel .control-next.control-arrow:before {
  content: '>';
}

.Projects-Carousel .control-prev.control-arrow:before {
  content: '<';
}

.project-item {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  width: 300px; 
  height: 400px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.project-content {
  position: relative;
  z-index: 2;
  height: 100%;
}

.project-title {
  font-size: 2rem;
  margin: 0 0 10px;
  text-shadow: 1cm  1cm 1cm rgba(0, 0, 0, 0.5);
}

.project-image {
  width: 100%;
  height: 60%;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.github-link {
  display: inline-flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin-top: 10px;
  font-size: 1em;
  transition: color 0.3s ease-in-out;
}

.github-link svg {
  margin-right: 5px;
}

.project-item:hover {
  background-color: white;
  transform: translateY(-10px);
}

.project-item:hover .project-title, .project-item:hover .github-link {
  color: black;
}
