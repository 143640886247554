circle {
    transition: stroke-dashoffset 0.8s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}
.circular-progress-bar svg {
    width: 100%;
    height: 100%;
}

